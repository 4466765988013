import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div id="about" class="section text-center landing-section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-md-offset-1">
            <h2>Page Not Found</h2>
            <h5>Opp this page can notbe found.</h5>
            <a href="/" class="btn btn-info btn-fill">Home</a>
          </div>
        </div>
      </div>
    </div>  
  </Layout>
)

export default NotFoundPage
